<template>
  <!-- Table Container Card -->
  <b-card style="height: 100% !important">

    <div class="">
      <!-- Tableret Top -->
      <b-row>

        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <!-- <label>Entradas</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            /> -->
          <!-- <b-button
              variant="primary"
              @click="getCustomer(1)"
            >
              Add Record
            </b-button> -->
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end" style="height: 10% !important;">
            <b-form-input v-model="computedQuerySearch" class="d-inline-block mr-1" placeholder="Busca una Orden..." />
            <v-select v-model="statusFilter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="statusOptions"
              class="invoice-filter-select" placeholder="Filtro x Estado">
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table ref="refInvoiceListTable" :items="computedFilterOrder" responsive :fields="[
      // { key: 'id_order', label: '#', sortable: true },
      { key: 'customer', label: 'CLIENTE', sortable: false },
      { key: 'logistic', label: 'ORDER', sortable: false },
      { key: 'timeline', label: 'FECHAS', sortable: false },
      { key: 'providers', label: 'ALIADOS', sortable: false },
      //   { key: 'invoiceStatus', sortable: true },
      //   { key: 'partner', sortable: true, label: 'Usuario' },
      // { key: 'total', sortable: true, formatter: val => `$${0}` },
      // { key: 'issuedDate', sortable: true },
      //   { key: 'created_at', sortable: true, label: 'Tipo' },
      // { key: 'balance', sortable: true },
      //       { key: 'actions', label: 'Acciones' },
    ]" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No se Encontraron Empleados que Coinciden"
      :sort-desc.sync="isSortDirDesc" class="serviceTableStyle">
      <template #head(invoiceStatus)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        {{ data.item.id_order }} 
      </template>
      <!-- Column: Id -->
      <template #cell(logistic)="data">

        <table class="orderTableStyle">
          <tr>
            <th style="width: 10% !important;">
              CANT
            </th>
            <th style="width: 25% !important;">
              ITEM
            </th>
            <th style="width: 25% !important;">
              COD
            </th>
            <th style="width: 20% !important;">
              Vr Uni
            </th>
            <th style="width: 20% !important;">
              Total
            </th>
          </tr>
          <tr v-for="(serviceItem, indexSer) in data.item.logistic.collect.items" v-bind:key="indexSer">
            <td>
              {{ serviceItem.quantity }}
            </td>
            <td>
              {{ serviceItem.name }}
            </td>
            <td>
              {{ serviceItem.code }}
            </td>
            <td>
              {{ serviceItem.price }}
            </td>
            <td>
              {{ serviceItem.price * serviceItem.quantity }}
            </td>
          </tr>
        </table>
      </template>

      <!-- Column: Id -->
      <template #cell(timeline)="data">
        <div class="font-weight-bold" v-for="(dates, indexD) in Object.keys(data.item.timeline)" v-bind:key="indexD">
          {{ Object.keys(data.item.timeline)[indexD] }} {{ Object.values(data.item.timeline)[indexD] }}
        </div>
      </template>

      <!-- Column: Id -->
      <template #cell(providers)="data">
        {{ orderList[0] }}
        <div class="font-weight-bold" v-for="(provider, indexP) in data.partners" v-bind:key="indexP">
           {{ provider }}
        </div>
        <div style="overflow-y: auto; height: 200px !important; width: 200px !important;">
          <img :src="'data:image/png;base64,' + photo.base64" style="width: 50%;" v-for="(photo, indexP) in list"
            v-bind:key="indexP" />
        </div>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(invoiceStatus)="data">
        <b-avatar :id="`invoice-row-${data.item.id_order}`" size="32"
          :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).variant}`">
          <feather-icon :icon="resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).icon" />
        </b-avatar>
        <b-tooltip :target="`invoice-row-${data.item.id_order}`" placement="top">
          <p class="mb-0">
            {{ data.item.invoiceStatus }}
          </p>
          <p class="mb-0">
            Balance: {{ data.item.balance }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.dueDate }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: Client -->
      <template #cell(customer)="data">
        <b-media vertical-align="center">
          <span style="font-size: 16px !important; font-weight: bold !important;">ORDER-000{{ data.item.id_order }}</span>
          <template #aside>
            <b-avatar size="32" :src="data.item.customer.avatar" :text="data.item.customer.avatar"
              :variant="`light-${resolveClientAvatarVariant(data.item.invoiceStatus)}`" />
            <!-- {{data.item.customer}} -->
          </template>

          <span class="font-weight-bold d-block text-nowrap">
            Precio Total {{ paymentDetailsList[(data.item.id_order - 1)].total_price }}<br />
            Descuento {{ paymentDetailsList[(data.item.id_order - 1)].total_discount }}<br />
            Impuesto {{ paymentDetailsList[(data.item.id_order - 1)].total_tax }}<br />
            Total{{ paymentDetailsList[(data.item.id_order - 1)].total }}
            <h4>{{ data.item.customer.full_name }}</h4>
          </span>
          <small class="text-muted">{{ data.item.customer.email
          }}</small><br />{{ data.item.customer.location.postal_address }} ({{ data.item.customer.location.city_name }},
          {{ data.item.customer.location.country_name }})
        </b-media>
        <div class="text-nowrap" style="border-bottom-style: solid !important; padding-bottom: 15px !important;">
          <h3>Acciones de la Order</h3>
          <feather-icon :id="`invoice-row-${data.item.id_order}-send-icon`" icon="SendIcon" class="cursor-pointer"
            size="25" @click="getOrderImages(data.item.id_order)" />
          <b-tooltip title="Enviar" class="cursor-pointer" :target="`invoice-row-${data.item.id_order}-send-icon`" />

          <feather-icon :id="`invoice-row-${data.item.id_order}-preview-icon`" icon="EyeIcon" size="25" class="mx-1"
            @click="describeOrder(data.item)" v-b-modal.modal-primary-order />
          <b-tooltip title="Vista Detallada" :target="`invoice-row-${data.item.id_order}-preview-icon`" />

          <!-- Dropdown -->
          <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">

            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Habilitar</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: data.item.id_order } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item> -->
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Deshabilitar</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item>
                <feather-icon icon="CopyIcon" />
                <span class="align-middle ml-50">Duplicate</span>
              </b-dropdown-item> -->
          </b-dropdown>
        </div>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(created_at)="data">
        <span class="text-nowrap">
          <b>{{ new Date(data.value / 1).toISOString().split('.')[0].split('T')[1].lastIndexOf('5') === -1 ? 'Aliado' :
            'Cliente' }}</b><br />
          {{ new Date(data.value / 1).toISOString().split('.')[0].split('T')[0] }} {{ new
            Date(data.value / 1).toISOString().split('.')[0].split('T')[1] }}

        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(balance)="data">
        <template v-if="data.value === 0">
          <b-badge pill variant="light-success">
            Paid
          </b-badge>
        </template>
        <template v-else>
          {{ data.value }}
        </template>
      </template>

      <!-- Column: Actions 
        <template #cell(actions)="data">
          

        </template> -->

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }}
            Documentos</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number
            class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BModal, VBModal
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted, defineComponent, ref, computed } from '@vue/composition-api'
import useInvoicesList from './useInvoiceList'
import AccountsManager from '@/lavarclick/ecommerce/AccountsManager.ts'
import invoiceStoreModule from './invoiceStoreModule'
import store from '@/store/index';
import Ripple from 'vue-ripple-directive';
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';
// import WebSocket from 'ws';

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect, BModal, VBModal
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {

    orderList: {
      type: Array,
      required: true
    }


  },
  methods: {

    getOrderImages(id_order) {
      this.list = [];
      new ECommerce().getOrderImages(id_order).then((images) => {
        console.log('imagitatta ', images.data);
        images.data.images.forEach((image) => {
          this.list.push(image);
        });


      });
    }


  },
  setup(props, { emit }) {
    let list = ref([]);
    const describeOrder = (orderDetail) => { emit('describeOrder', orderDetail) };
    let totalPrice = ref(0);
    let totalDiscount = ref(0);
    let totalTax = ref(0);
    let totalServicesItems = ref(0);
    let filterableOrders = ref([]);

    const calculatePayment = (order) => {
      order.logistic.collect.items.forEach((serviceitem) => {
        totalPrice.value += ((serviceitem.quantity * 1) * (serviceitem.price * 1))
        totalServicesItems.value += serviceitem.quantity * 1;

      });
    };
    const paymentDetails = ref({});
    const paymentDetailsList = ref([]);
    const computedOrder = computed({
      get: () => {
        props.orderList.forEach((orderr, indexOrderr) => {
          calculatePayment(props.orderList[indexOrderr]);
          paymentDetailsList.value.push({
            total_price: totalPrice.value,
            total_services: props.orderList[indexOrderr].logistic.collect.items.length,
            total_service_items: totalServicesItems.value,
            total_discount: totalDiscount.value,
            total_tax: totalTax.value,
            total: (totalPrice.value - totalDiscount.value + totalTax.value)
          });
          totalDiscount.value = 0;
          totalPrice.value = 0;
          totalTax.value = 0;
          totalServicesItems.value = 0;

        });
        return props.orderList;
      },
    });

    let computedFilterOrder = computed({
      get: () => { return filterableOrders.value; },
      set: (orders) => {
        filterableOrders.value = orders;
      }
    });

    let search_query = ref('');
    const ecommerce = ref(new ECommerce());

    const computedQuerySearch = computed({
      get: function () {

        console.log('cambiando ', search_query, computedFilterOrder, computedOrder); return search_query.value
      },
      set: function (querySearch) {
        search_query.value = querySearch;

        if (querySearch !== '') {

          ecommerce.value.filterOrders(computedOrder.value, search_query.value).then((orderMatch) => {

            computedFilterOrder.value = orderMatch;

          });
        } else {

          computedFilterOrder.value = computedOrder.value;

        }
      }
    });

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'Activos',
      'Inactivos',
      'Pausados',
      'Ordenes Activas'
    ]

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      statusFilter,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      accountsManager
    } = useInvoicesList();

    // let connection = new WebSocket('ws://lavar.click/lavarclickconsole');

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      computedOrder,
      paymentDetails,
      paymentDetailsList,
      isSortDirDesc,
      refInvoiceListTable,
      computedQuerySearch, list,
      computedFilterOrder,
      statusFilter,
      describeOrder,
      refetchData,
      statusOptions,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      accountsManager: new AccountsManager(),
      filterableOrders,
      // connection
    }
  },
  sockets: {
    connection: () => {
      console.log('socket connected ')
    },
    chatmessage: () => {
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    }
  },
  mounted() {

    this.computedQuerySearch = '';
    this.sockets.subscribe('chatmessage', (data) => {
      console.log('llegando ', data);
    });
    

    // const connection = new WebSocket('ws://lavar.click/lavarclickconsole');
    // connection.onmessage = (event) => {
    //   // Vue data binding means you don't need any extra work to
    //   // update your UI. Just set the `time` and Vue will automatically
    //   // update the `<h2>`.
    //   console.log('entrando ', event);
    // }


  },

});
</script>
  
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.serviceTableStyle {
  margin-left: 0px !important;
  border-collapse: collapse;
  font-family: 'MavenPro' !important;
  border-radius: 10px !important;
  width: 100% !important;
  margin-top: 0px !important;
  overflow-y: auto !important;
}


.serviceTableStyle th {
  border: #4e95f4 0px solid;
  font-size: 14px !important;
  color: #ffffff !important;
  background-color: #6d36ac !important;
  text-align: justify !important;
  font-family: 'MavenPro' !important;
  height: 20px !important;
}

.serviceTableStyle td {
  border: #4e95f4 0px solid;
  text-align: justify !important;
  font-family: 'MavenPro' !important;

}

/* provide some minimal visual accomodation for IE8 and below */
.serviceTableStyle tr {
  background: #b8d1f3;
}

/*  Define the background color for all the ODD background rows  */
.serviceTableStyle tr:nth-child(odd) {
  background: #f2edf7;
}

/*  Define the background color for all the EVEN background rows  */
.serviceTableStyle tr:nth-child(even) {
  background: #f2faf0;
}

.orderTableStyle {
  margin-left: 0px !important;
  border-collapse: collapse;
  font-family: 'MavenPro' !important;
  border-radius: 10px !important;
  width: 100% !important;
  margin-top: 0px !important;
  overflow-y: auto !important;
  height: 100px !important;
}

.orderTableStyle th {
  border: #4e95f4 0px solid;
  font-size: 11px !important;
  color: #ffffff !important;
  background-color: #6d36ac !important;
  text-align: justify !important;
  font-family: 'MavenPro' !important;
  border-radius: 10px !important;
}

.orderTableStyle td {
  border: #4e95f4 0px solid;
  text-align: justify !important;
  font-family: 'MavenPro' !important;

}

/* provide some minimal visual accomodation for IE8 and below */
.orderTableStyle tr {
  background: #b8d1f3;
}

/*  Define the background color for all the ODD background rows  */
.orderTableStyle tr:nth-child(odd) {
  background: #f2edf7;
}

/*  Define the background color for all the EVEN background rows  */
.orderTableStyle tr:nth-child(even) {
  background: #f2faf0;
}
</style>
  